import * as React from 'react';
import styles from './styles.module.scss';
import { useTranslation } from 'react-i18next';
import { Controller, useForm } from 'react-hook-form';
import Input from '@components/Input';
import { emailRegex } from '@utils/index';
import Button, { BUTTON_MODE, BUTTON_TYPE } from '@components/Button';
import Spinner from '@components/Spinner';
import 'react-phone-number-input/style.css';
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input';

import { doc, setDoc, updateDoc } from 'firebase/firestore';
import { updateProfile } from 'firebase/auth';
// import Avatar, { AVATAR_SIZE } from '@components/Avatar';
// import FeatherIcon from 'feather-icons-react';
import Modal from 'react-modal';

// import {
// getDownloadURL,
// getStorage,
// ref,
// uploadBytes,
// deleteObject,
// } from 'firebase/storage';
// import { ProfileImageContext } from '@contexts/ProfileImageContext';
import UserPrompt from '@components/UserPrompt';
// import { UserCardContext } from '@contexts/UserCardContext';

export type User = {
  firstName: string;
  lastName: string;
  email: string;
  therapist: string;
  phone: string;
  aacModel: string;
  otherModel: string | undefined;
};

type Dirty = {
  firstName: boolean;
  lastName: boolean;
  email: boolean;
  therapist: boolean;
  phone: boolean;
  aacModel: boolean;
  otherModel: boolean;
};

type Props = {
  user: any;
  db: any;
  auth: any;
  userData: any;
  avatarPhoto: string;
  userType: string;
};

export default function PersonalDetails({
  userData,
  user,
  db,
  userType,
  auth,
  avatarPhoto,
}: Props) {
  const [isLoading, setIsLoading] = React.useState(false);
  const [error, setErrors] = React.useState('');

  const [showPrompt, setShowPrompt] = React.useState(false);
  const [currentCountry, setCurrentCountry] = React.useState<any>();
  // const { userData } = React.useContext(UserCardContext);

  const [changeInfoProfileSuccessfully, setChangeInfoProfileSuccessfully] =
    React.useState(false);

  const getCountryCode = React.useCallback(async () => {
    try {
      const response = await fetch('https://api.country.is');
      const country = await response.json();
      setCurrentCountry(country.country ?? 'ZZ');
    } catch (error) {
      setCurrentCountry('ZZ');
    }
  }, []);

  const {
    register,
    handleSubmit,
    setError,
    watch,
    control,
    setValue,
    formState: { errors },
  } = useForm({ mode: 'onSubmit' });

  // const { setImageURLProfile, imageURLProfile } =
  //   React.useContext(ProfileImageContext);
  const firstName = watch('firstName');
  const lastName = watch('lastName');
  const email = watch('email');
  const otherModel = watch('otherModel');
  const aacModel = watch('aacModel');
  const therapist = watch('therapist');
  const phone = watch('phone');

  // const storage = getStorage();

  const [isDirty, setIsDirty] = React.useState<Dirty>({
    firstName: false,
    lastName: false,
    email: false,
    aacModel: false,
    otherModel: false,
    therapist: false,
    phone: false,
  });

  const { t } = useTranslation('dashboard');

  const { ref: firstNameRef, ...firstNameInputProps } = register('firstName', {
    required: t('personalDetails.form.errorsState.required.firstname'),
  });

  const { ref: lastNameRef, ...lastNameInputProps } = register('lastName', {
    required: t('personalDetails.form.errorsState.required.lastname'),
  });

  const { ref: emailRef, ...emailInputProps } = register('email', {
    required: t('personalDetails.form.errorsState.required.email'),
    pattern: {
      value: emailRegex,
      message: t('personalDetails.form.errorsState.invalid.email'),
    },
  });

  const { ref: therapistRef, ...therapistInputProps } = register('therapist', {
    required: t('personalDetails.form.errorsState.required.therapist'),
  });

  const { ref: aacModelRef, ...aacModelInputProps } = register('aacModel', {
    required: t('personalDetails.form.errorsState.required.aacModel'),
  });

  React.useEffect(() => {
    if (userData) {
      setValue('aacModel', userData?.aacModel || '');
      setValue('phone', userData?.phone ?? '');
      setValue('therapist', userData?.therapist);
    }
  }, [userData]);

  function handleOnSubmit({
    firstName,
    lastName,
    email,
    aacModel,
    otherModel,
    therapist,
    phone,
  }: User) {
    setIsDirty({
      email: email.length > 0,
      aacModel: aacModel.length > 0,
      otherModel: otherModel && otherModel.length > 0,
      therapist: therapist.length > 0,
      phone: phone.length > 0,
      firstName: firstName.length > 0,
      lastName: lastName.length > 0,
    } as Dirty);
    setIsLoading(true);
    onUpdate({
      email,
      aacModel,
      otherModel,
      therapist,
      phone,
      firstName,
      lastName,
    } as User);
  }

  const onUpdate = React.useCallback(
    async ({
      firstName,
      lastName,
      email,
      aacModel,
      otherModel,
      therapist,
      phone,
    }: User) => {
      try {
        if (user) {
          if (user?.email !== email) {
            setShowPrompt(true);
          } else {
            setIsLoading(true);
            await updateProfile(auth.currentUser, {
              displayName: `${firstName} ${lastName}`,
            });

            await updateDoc(doc(db, 'users', user.uid), {
              firstname: firstName,
            });

            await setDoc(
              doc(db, `users/${user.uid}/private`, 'library'),
              {
                firstname: firstName,
                lastname: lastName,
                aacModel: aacModel === 'other' ? otherModel : aacModel,
                email,
                therapist,
                phone: phone,
              },
              { merge: true }
            );

            if (userType === 'both') {
              await setDoc(
                doc(db, `users/${user.uid}/private`, 'donor'),
                {
                  firstname: firstName,
                  lastname: lastName,
                  email,
                },
                { merge: true }
              );
            }

            setChangeInfoProfileSuccessfully(true);
            setTimeout(() => {
              setChangeInfoProfileSuccessfully(false);
            }, 3000);
            setIsLoading(false);
          }
        }
      } catch (err: any) {
        if (err instanceof Error) {
          setErrors(err.message);
        }
      } finally {
        setIsLoading(false);
      }
    },
    [user, db]
  );

  React.useEffect(() => {
    getCountryCode();
  }, [getCountryCode]);

  React.useEffect(() => {
    if (errors?.message) {
      setError(errors.type, { type: 'manual', message: errors.message });
    }
  }, [errors, setError]);

  function closeModal() {
    setShowPrompt(false);
  }

  Modal.setAppElement('div');

  return (
    <>
      <Modal
        isOpen={showPrompt}
        onRequestClose={closeModal}
        overlayClassName={styles.overlay}
        className={styles.modal}
      >
        <UserPrompt
          db={db}
          user={user}
          firstName={firstName}
          lastName={lastName}
          newEmail={email}
          currentAuthUser={auth?.currentUser}
          closeModal={closeModal}
        />
      </Modal>

      <div className={styles.container}>
        <form onSubmit={handleSubmit(handleOnSubmit as any)}>
          <fieldset className={styles.isHalf}>
            <Input
              hasValue={isDirty?.firstName ?? false}
              label={t('personalDetails.form.labels.firstName')}
              type="text"
              placeholder={t('personalDetails.form.placeholder.firstName')}
              inputRef={firstNameRef}
              defaultValue={user?.displayName.split(' ')[0] ?? ''}
              {...firstNameInputProps}
              error={errors?.firstName?.message}
            />

            <Input
              hasValue={isDirty?.lastName ?? false}
              label={t('personalDetails.form.labels.lastName')}
              type="text"
              defaultValue={user?.displayName.split(' ')[1] ?? ''}
              placeholder={t('personalDetails.form.placeholder.lastName')}
              inputRef={lastNameRef}
              {...lastNameInputProps}
              error={errors?.lastName?.message}
            />
          </fieldset>
          <fieldset>
            <Input
              hasValue={isDirty?.aacModel ?? false}
              label={t('personalDetails.form.labels.aacModel')}
              type="text"
              placeholder={t('personalDetails.form.placeholder.aacModel')}
              inputRef={aacModelRef}
              defaultValue={user?.email ?? ''}
              {...aacModelInputProps}
              error={errors?.aacModel?.message}
            />
            {errors.aacModel && (
              <p
                className={styles.errorMessage}
                style={{
                  fontWeight: 400,
                  marginTop: '-8px',
                  marginBottom: '16px',
                }}
              >
                {errors.aacModel.message}
              </p>
            )}

            <Input
              hasValue={isDirty?.therapist ?? false}
              // defaultValue={userData?.therapist ?? ''}
              label={t('personalDetails.form.labels.therapist')}
              type="text"
              inputRef={therapistRef}
              {...therapistInputProps}
              error={errors?.therapist?.message}
            />

            <Controller
              control={control}
              name="phone"
              rules={{
                required: true,
                validate: (value) => {
                  return value ? isValidPhoneNumber(value) : true;
                },
              }}
              render={({ field: { onChange, value } }: any) => (
                <>
                  <label className="controlled-component-label">
                    {t('personalDetails.form.labels.phone')}
                  </label>

                  <PhoneInput
                    defaultCountry={currentCountry}
                    className={errors?.phone ? 'error' : ''}
                    placeholder={t('personalDetails.form.placeholder.phone')}
                    value={userData?.phone ?? value}
                    onChange={onChange}
                  />
                </>
              )}
            />

            {errors?.phone?.type === 'validate' ? (
              <p
                className={styles.errorMessage}
                style={{
                  fontWeight: 400,
                  marginBottom: '16px',
                }}
              >
                {t('personalDetails.form.errorsState.invalid.phone')}
              </p>
            ) : (
              <p
                className={styles.errorMessage}
                style={{
                  fontWeight: 400,
                  marginBottom: '16px',
                }}
              >
                {errors?.phone?.message}
              </p>
            )}

            <div style={{ marginTop: '16px' }}>
              <Input
                hasValue={isDirty?.email ?? false}
                label={t('personalDetails.form.labels.emailAddress')}
                type="email"
                placeholder={t('personalDetails.form.placeholder.emailAddress')}
                inputRef={emailRef}
                defaultValue={user?.email ?? ''}
                {...emailInputProps}
                error={errors?.email?.message}
              />
            </div>
          </fieldset>
          <div className={styles.buttonUpdate}>
            <Button
              type={BUTTON_TYPE.PRIMARY}
              mode={BUTTON_MODE.OUTLINE}
              disabled={
                !firstName ||
                !lastName ||
                !email ||
                !phone ||
                !therapist ||
                (!aacModel && aacModel !== 'other') ||
                (!otherModel && aacModel === 'other')
              }
            >
              {isLoading ? (
                <Spinner isMid />
              ) : (
                t('personalDetails.form.labels.update')
              )}
            </Button>
            {error.length ? (
              <p className={styles.errorMessage}>{error}</p>
            ) : null}
            {changeInfoProfileSuccessfully && (
              <p className={styles.success}>
                {t('personalDetails.form.success.profileChanged')}
              </p>
            )}
          </div>
        </form>
      </div>
    </>
  );
}
